.about {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    transition: opacity 0.5s ease-in-out;
  }

  .about-head {
    transform: scale(0);
    transition: transform 0.8s ease-in-out;
    margin-bottom: 20px;
    
  }
  .about-head h1{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .about-head span {
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    color: #42C2FF;
  }
  
  .about-desc {
    max-width: 800px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.5s ease-in-out,opacity 0.5s ease-in-out;
    text-align: justify;
    text-justify: inter-word;
  }
  
  .animate-head {
    transform: scale(1) !important;
  }
  
  .animate-desc {
    opacity: 1;
    transform: translateY(0) !important;
  }
