.event-blog {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 107px;
  padding: 0 20px;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.event-item {
  flex: 0 0 auto;
  width: 500px;
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.event-item .date {
  text-align: center;
  color: #666666;
}
.img {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-blog-head {
  text-align: center;
  margin-bottom: 20px;
}

.event-blog-head h1 {
  margin: 0;
  padding: 20px;
  font-size: 2rem;
  color: #333;
}

.desc {
  padding: 15px;
  text-align: center;
}

.desc p {
  margin: 0;
  color: #000;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.container:hover {
  animation-play-state: paused;
}
