
.reg-head{
    padding-top: 80px;

text-align: center; 
width: 100%;
color: rgb(14, 13, 13);
margin-top: 100px;
padding-bottom: 50px;

}
.reg-head h1{

    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 36px;
    font-style: normal;  
}
.reg-container{
display: flex;
flex-direction: column;
align-items:center ;
background: linear-gradient(135deg,#F2F2F2,#DBDBDB);
  padding-bottom: 40px;
}
.reg-btn{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    max-width: 500px;
    width: 90%;
   padding: 20px;
    background-color: #fdfcfc;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
}
.reg-btn button{
   
    background: linear-gradient(135deg,#42C2FF,#85F4FF);
    padding: 5px 15px;
    white-space: nowrap;
    text-align: center;
    border-radius: 30px;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    font-size: 14px;
    border: 0;
    outline: 0;
    width: 100%;
}
