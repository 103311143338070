.event-gallery {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.event-gallery-head {
  text-align: center;
  margin-bottom: 20px;
}

.event-gallery-controls {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.event-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.event-image-container {
  flex: 0 0 33.0%; /* Show 3 images at a time */
  height: 500px; /* Fixed height for the container */
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease, opacity 0.5s ease;
  margin: 0 10px; /* Space between images */
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.event-image-container {
  opacity:1;
  transform: scale(1); /* Scale down images not in the center */
}

.event-image-container.active {
  opacity: 1;
  transform: scale(1); /* Scale up the center image */
}

.slider-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
