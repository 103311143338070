.nav-banner {
    background: linear-gradient(90deg,#0e0d0d,#444344);
    margin-top: 90px;
}
.test {
    height: 100vh;
}
.homepage hr {
    width: 95%;
    margin: auto;
}
.dir-msg-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 100px;
    padding: 10px 50px;
} 
.dir-img {
    width: 300px;
    overflow: hidden;
}
.dir-img img {
    width: 300px;
}
.dir-msg {
    max-width: 700px;
}
.dir-msg p {
    text-align: justify;
    text-justify: center;
    position: relative;
}
.dir-msg .left-quote {
    position: absolute;
    top: -30px;
    left: -20px;
    color: #42C2FF;
}
 .dir-msg .right-quote {
    position: absolute;
    bottom: -10px;
    right: -20px;
    color: #42C2FF;
 }
 .dir-msg-head {
    margin-bottom: 20px;
 }
 .dir-msg-head h1 {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
 }

 .nav-banner {
    background: linear-gradient(90deg,#0e0d0d,#444344);
    margin-top: 107px;
}

.test {
    height: 100vh;
}
.homepage hr {
    width: 95%;
    margin: auto;
    position: relative;
}
.absolute{
    position: absolute;
}
.relative{
    position: relative;
}

.popup-container {
    /* Ensure it has enough space below the navbar */
    position: absolute;
    margin-top: 5px; /* Adjust if your navbar height is different */
    z-index: 99;
  }
  
  .popup-container .relative {
    position: relative;
    /* Add any additional styling for your popup if needed */
  }
 
.blurred {
    filter: blur(5px);
}