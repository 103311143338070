.banner {
    margin-top: 107px;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #2ea8e0;
}
.startup-dir-container{
    width: 100vw;
    
}
.startup-dir-head {
    margin-top: 107px;
}
.startup-dir-head h1{
    align-items: center;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}