.as-banner {
    width: 100vw;
    height: 350px;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    border-radius: 10px;
    position: relative;
    margin-top: 90px;
  }
  .aboutus-page hr {
    width: 90%;
    margin: 15px auto;
  }
  .as-banner-fade{
    width: 100%;
    height: 287.5px;
    background-color: #42C2FF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.2;
    border-radius: 10px;
  }
  .as-banner h1{
    color: #fff;
    opacity: 1 !important;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .as-banner h1 {
    font-size: 70px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .as-banner-img img {
      width: 100%;
      height: 300px;
      display: block;
      /* filter: grayscale(100%); */
  }
  .as-banner-img{
    flex: 1;
  }
  .as-banner-img1 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px
  }
  .as-banner-img2 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
  }
  .vision {
    width: 100vw;
  }
  
  .vision-head h1 {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .vision-head span {
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    color: #42C2FF;
  }
  
  .vision-left {
    position: relative;
    max-width: 500px;
    padding: 30px;
    margin-left: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    transform: translateX(-50px);
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  
  .vision-left p {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    text-align: justify;
    text-justify: inter-word;
  }
  .vision-left .left-quote {
    position: absolute;
    top: 0;
    left: 0;
    color: #42C2FF;
  }
  .vision-left .right-quote {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #42C2FF;
  }
  .vision-left h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .vision-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    width: 100%;
  }
  
  .vision-right {
    width: 40%;
    transform: translateY(30px);
    opacity: 0;
    transition: transform 1s, opacity 1s;
  }
  .vision-right img {
    width: 100%;
  }
  .mission-left {
    width: 40%;
    transform: translateY(30px);
    opacity: 0;
    transition: transform 1s, opacity 1s;
}
  .mission-left img {
    width: 100%;
  }
  
  .mission-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding-bottom: 30px;
  }
  
  .mission-right {
    max-width: 600px;
    width: 90%;
    transform: translateX(50px);
    opacity: 0;
    transition: transform 1s, opacity 1s;
  }
  
  .mission-head h1 {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    transform: scale(0);
    transition: transform 0.5s ease-in-out;
  }
  
  .mission-head span {
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    color: #42C2FF;
  }
  
  .mission-right-head h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 40px;
  }
  
  .mission-stmt {
    border-left: 3px solid #42C2FF;
    padding-left: 10px;
    margin-bottom: 30px;
  }
  
  .mission-stmt p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .animate-vision-head h1 {
    transform: scale(1);
  }
  
  .animate-vision-desc .vision-left,
  .animate-vision-desc .vision-right {
    transform: translateY(0);
    transform: translateX(0);
    opacity: 1;
  }
  
  .animate-mission-head h1 {
    transform: scale(1);
  }
  
  .animate-mission-bottom .mission-left,
  .animate-mission-bottom .mission-right {
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width:1000px) {
    .vision-desc {
      flex-direction: column;
    }
    .mission-bottom {
      flex-direction: column;
    }
    .mission-left {
      width: 80%;
    }
    .vision-right {
      width: 80%;
    }
  }
  