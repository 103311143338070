.chairmen-director-head ,.directors-head ,.mentors-head,.consultant-head,.team-head {
    text-align: center; 
    width: 100%;
}
.chairmen-director-head{
    padding-top: 30px;
    margin-top: 90px;
}
.chairmen-director,.directors,.mentors,.consultant,.team{
    background: linear-gradient(135deg,#F2F2F2,#DBDBDB);
}

.chairmen-director head h1,.directors-head h1 ,.mentors-head h1,.consultant-head h1,.team-head h1{
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}
.chairmen-director-cards,.director-cards ,.mentor-cards,.consultant-cards ,.team-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 150px;
    margin-top: 50px;
    padding-bottom: 30px;
}

.team-page hr{
    width: 90%;
    margin: auto;
}