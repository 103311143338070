/* Add this to StartupSangamPopup.css */
.popup-container {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    /* z-index: 50; */
   
  }
  .animate{
    animation: slideIn 1s cubic-bezier(0, 1.28, 0.55, 0.01);
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #d0fcb3;
  }
 
  .blurred {
    backdrop-filter: blur(10px);
  }
 