.event-hero{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin: auto;
    margin-top: 107px;
}
.event-hero-right{
    width: 50%;
    object-fit: cover;
}
.event-hero-right img{
    width: 100%;
}
.event-hero-left{
    width: 50%; 
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.event-hero-left-desc{
    margin-top: 50px;
}
.event-hero-left-desc p{
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: justify;
    text-justify: inter-word;
}
.event-hero-left-head h4{
    font-size: 18px;
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    color: #42C2FF;
}
.event-hero-left-head h1{
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    font-style: normal;  
}

.know-more-btn {
    display: inline-block;
    padding: 10px 0;
    color: #000;
    text-decoration: none;
    position: relative;
  }
  .know-more-btn .circle {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    background-color: #42C2FF;
    display: flex;
    transition: width 0.5s ease;
  }
  .know-more-btn .button-text {
    position: absolute;
    top: 30px;
    left: 80px;
    white-space: nowrap;
    z-index: 1;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    transition: all 0.3s;
  }
  .know-more-btn:hover .circle {
    width: 220px;
  }
  .know-more-btn:hover .button-text {
    color: #fff !important;
  }
  .arrow {
    position: absolute;
    top: 28px;
    left: 20px;
    transition: all 0.5s;
  }
  .arrow::before {
    display: block;
    opacity: 0;
    position: absolute;
    top: 13px;
    left: -3px;
    border-radius: 10px;
    content: '';
    width: 20px;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s;
  }
  .know-more-btn:hover .arrow::before {
    opacity: 1;
  }
  .know-more-btn:hover .arrow {
    transform: translateX(8px);
  }

  @media (max-width: 970px) {
    .event-hero {
      flex-direction: column;
      align-items: center;
      width: 100vw;
    }
    .event-hero-left {
      width: 100%;
    }
    .event-hero-right {
      width: 100%;
    }
    .event-hero-left {
      padding: 40px;
    }
    .event-hero-left-desc {
      margin-top: 30px;
    }
  }
  @media (max-width: 480px) {
    .event-hero-left-head h4 {
      font-size: 28px;
    }
    .event-hero-left-head h1 {
      font-size: 32px;
    }
  }