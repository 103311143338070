.carousel-item {
    position: relative;
    transition: transform 1s ease-in-out; /* Smooth transition for the carousel items */
  }
  
  .carousel-caption {
    width: fit-content;
    position: absolute;
    top: 40%;
    left: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    
  }
  
  .carousel-item.active .carousel-caption {
    opacity: 1;
    transform: translateY(0);
  }
  .carousel-item.active {
    opacity: 1;
  }
  .carousel-item img {
    height: 600px;
    object-fit: cover;
    user-select: none;
  }
  #udbhav-cap {
    margin-top: 30px;
  }
  .carousel-caption h5 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .carousel-caption p {
    font-size: 1.25rem;
  }
