
.startup-dir-container{
    width: 100vw;
    /* padding-top: 300px; */
    
}
.pre-head{
    align-items: center;
    text-align: center;
    margin-top: 107px;
}
.pre-head h1 {
    align-items: center;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}